import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Side_bar = _resolveComponent("Side-bar")!
  const _component_Layout = _resolveComponent("Layout", true)!

  return (_ctx.show)
    ? (_openBlock(), _createBlock(_component_Layout, {
        key: 0,
        style: {"padding-top":"60px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Header, { style: {"position":"fixed","top":"0","z-index":"9"} }),
          _createVNode(_component_Side_bar, { ref: "SideBar" }, null, 512)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}